import React from 'react'
import CTA from '../components/cta'
import Seo from '../components/seo'
import Layout from '../components/layout'
import patternHex from '../images/backrounds/hire-a-designer-scaled.jpg'

const Index = ({ location }) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title='Hire a Designer'
        description='Talk to us about hiring a graphic designer, web designer, UI/UX specialist, mobile App designer or consultant.'
      />
      <section>
        <div
          className='hero'
          style={{
            backgroundImage: `url(${patternHex})`,
          }}
        >
          <div className='hero-title bg-primary-transparent'>
            <h1 className='text-white'>hire a designer</h1>
            <p className='text-white'>
              Make 729 Solutions the place you go to hire a graphic designer, web designer, UI/UX specialist, mobile App designer or consultant. Whether you’re
              looking for some short-term assistance or long-term horsepower, we’ve got the talent to fill in behind your team on any project, large or small.
            </p>
          </div>
        </div>
      </section>
      <section className='mt-6'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h2>How It Works</h2>
              <ol className='text-primary'>
                <li className='mb-3'>
                  <span className='text-dark'>
                    <b>What kind of designer do you need to hire?</b> Our designers have a broad range of skillsets in both print and digital media. Whether
                    you’re starting a project from scratch and need a full planning workshop, or you need someone to jump into the middle of an existing effort
                    – we’re here to help you succeed.
                  </span>
                </li>
                <li className='mb-3'>
                  <span className='text-dark'>
                    We’ll scour our team to <b>see who’s skill set matches your needs perfectly</b> to help you get where you need to be. Whether it’s a
                    specific design skill or full-spectrum design assistance, we’ve got you covered.
                  </span>
                </li>
                <li>
                  <span className='text-dark'>
                    <b>We can work within your existing brand guide, create a brand guide for you, or anything in-between!</b> We’re versatile, flexible, and
                    will collaborate in whatever way best suits your needs.
                  </span>
                </li>
              </ol>
              <div className='text-center'>
                <CTA href='/contact-us/' text='Schedule a Call' className='btn btn-primary' />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className='d-flex bg-pattern-trihex bg-dark my-6'
        style={{
          backgroundSize: '280px',
        }}
      >
        <div className='container py-6'>
          <div className='row'>
            <div className='col text-center'>
              <h2 className='text-white'>we’ll set you up with designers of the highest caliber.</h2>
              <p className='text-white'>
                We can help with any project – from mobile apps and responsive websites, logos, and brochures. We’re here to help make you look good and think
                outside the box.
              </p>
              <CTA href='/contact-us/' text='Request a Call' className='btn btn-primary' />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-12 text-center'>
              <h2 className='mb-1'>need a designer with a specific skill set?</h2>
              <p>Our team has experience in the following areas of expertise – and we’re happy to introduce you to them today!</p>
            </div>
          </div>
          <div className='row mt-6'>
            <div className='col-12 col-lg-4'>
              <h3>project design</h3>
              <p>
                Design Workshops
                <br />
                Efficiency Reviews
                <br />
                Project Detail Sheets
                <br />
                User Experience
                <br />
                User Flow
                <br />
                User Interface
                <br />
                User Personas
              </p>
              <h3>branding</h3>
              <p>
                Brand Identity
                <br />
                Business Cards
                <br />
                Icon Design
                <br />
                Letterhead
                <br />
                Logos
                <br />
                Social Media Graphics
              </p>
            </div>
            <div className='col-12 col-lg-4 mt-5 mt-lg-0'>
              <h3>digital collateraL</h3>
              <p>
                Brochure Sites
                <br />
                Ecommerce Design
                <br />
                Help Center Design
                <br />
                Interactive Demos
                <br />
                Landing Pages
                <br />
                Mobile App Design
                <br />
                Presentations
                <br />
                Shopify Websites
                <br />
                Web Banners
                <br />
                Web Forms
                <br />
                Website Design
                <br />
                WordPress Websites
              </p>
            </div>
            <div className='col-12 col-lg-4 mt-5 mt-lg-0'>
              <h3>print collateral</h3>
              <p>
                Annual Reports
                <br />
                Book Layout
                <br />
                Brochures
                <br />
                Case Studies
                <br />
                Flyers
                <br />
                Package Design
                <br />
                Postcards
                <br />
                Posters
                <br />
                Vehicle Wraps
                <br />
                White Papers
              </p>
            </div>
          </div>
          <div className='row mt-5 mb-6'>
            <div className='col-12 text-center'>
              <CTA href='/contact-us/' text='Schedule A Call' className='btn btn-primary' />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Index
